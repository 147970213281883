import "../../assets/scss/app.scss";

class Main {
  constructor() {
    const menu = document.querySelector("header .menu");
    const menuBtn = document.querySelector(".menu-wrap > button");
    let menuFlg = false;
    const body = document.querySelector("body");

    menuBtn.addEventListener("click", () => {
      if (menuFlg) {
        menuFlg = false;
        menu.classList.remove("open");
        menuBtn.classList.remove("open");
      } else {
        menuFlg = true;
        menu.classList.add("open");
        menuBtn.classList.add("open");
      }
    });
  }
}

window.addEventListener("DOMContentLoaded", () => {
  new Main();
});
